@import '../../../styles/marketplace.css';

.cta {
  padding: 91px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 32px;
  background-image: url('../../../assets/become-pro/bg-mobile.svg');
  background-color: #e6f275;
  background-blend-mode: lighten;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  max-width: 1216px;
  width: 100%;
  margin-bottom: 80px;
  @media (--viewportLarge) {
    background-image: url('../../../assets/become-pro/bg-desktop.svg');
    margin-bottom: 0px;
  }
}

.cta_title {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 32 Heading */
  font-family: 'Bricolage Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.64px;
  max-width: 500px;
  margin: 0;
}

.cta_desc {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0;
  margin-top: 8px;
}
