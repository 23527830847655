@import '../../../styles/marketplace.css';

.carouselContainer {
  width: 100%;
  max-width: 1280px;
  padding: 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 0px 32px;
  }
}

.carouselItem {
  position: relative;
  background: #313131;
  height: 600px;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 80px 40px;

  @media (--viewportMedium) {
    height: auto;
    border-radius: 48px !important;
    background: none;
    padding: 0px;
    display: inline-block !important;
  }
}

.carouselImageDesktop {
  width: 100%;
  height: 100%;
  display: none !important;
  position: relative;

  @media (--viewportMedium) {
    border-radius: 48px !important;
    height: auto;
    display: block !important;
  }
}

.carouselImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: block;
  object-fit: cover;

  @media (--viewportMedium) {
    display: none !important;
  }
}

.carouselContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 296px;

  @media (--viewportMedium) {
    align-items: flex-start;
    width: 494px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 70px;
  }
}

.carouselTitle {
  position: relative;

  color: #fff;
  font-family: 'Bricolage Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 150% */
  letter-spacing: -0.64px;
  margin: 0;
  text-align: center;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    font-size: 64px;
    text-align: left;
    font-weight: 700;
    line-height: 78px; /* 150% */
    letter-spacing: -0.8px;
  }
}

.carouselText {
  color: var(--color-gray-0, #fff);

  /* ProLed 24 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  position: relative;
  margin: 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    font-size: 24px;
    line-height: 36px; /* 150% */
  }
}

.cta {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  color: var(--typo-primary, #1c1c1c);
  text-align: center;
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  padding: 8px 24px;
  border-radius: 8px;
  background: #e6f275;
  position: relative;
  margin-top: 32px;

  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 40px;
  }
}

.button {
  padding: 8px 24px;
  position: relative;
  margin-top: 32px;
  @media (--viewportMedium) {
    margin-top: 40px;
    line-height: 30px;
  }
}

.carouselDots {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  gap: 8px;

  @media (--viewportMedium) {
    left: auto;
    right: 88px;
    bottom: 48px;
  }
}

.carouselDot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.carouselDotActive {
  background-color: #fff;
}
