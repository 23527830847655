@import '../../../styles/marketplace.css';

.homeCategoriesContianer {
  display: none;
  justify-content: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fff;

  @media (--viewportMedium) {
    display: flex;
  }
}

.homeCategories {
  width: auto;
  display: flex;
  padding: 8px 32px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  @media (min-width: 1440px) {
    padding: 8px 112px;
    width: 1440px;
  }
}

.menuLabel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  color: var(--typo-primary, #1c1c1c);
  text-align: center;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @media (--viewportLarge) {
    padding: 6px 16px;
  }
}

.menuLabel svg {
  margin-top: 4px;
}

.menuContent {
  padding: 8px 0px;
  width: fit-content;
  border-radius: 8px;
}

.menuItem {
  white-space: nowrap;
  padding: 6px 16px;
  cursor: pointer;
}

.menuItem:hover {
  background: #dfedf7;
}
