@import '../../../styles/marketplace.css';

.allServices {
  display: flex;
  padding: 40px 24px 80px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1280px;

  @media (--viewportMedium) {
    gap: 24px;
    padding: 0 24px;
  }
}

.allServicesTitle {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 32 Heading */
  font-family: Bricolage Grotesque;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.64px;
  margin: 0;

  @media (--viewportMedium) {
    text-align: left;
    width: 100%;
  }
}

.allServicesItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.allServicesItem {
  width: 100%;

  border-radius: 32px;
}

.allServicesItemBanner {
  height: 240px;
  position: relative;
}

.allServicesItem img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 32px;
}

.allServicesItemTitle {
  color: #fff;

  /* ProLed 24 Semibold */
  font-family: 'sofiapro';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  position: absolute;
  top: 40px;
  left: 40px;
}

.allServicesItemDesc {
  color: #fff;
  text-align: center;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  position: absolute;
  top: calc(40px + 24px * 2);
  left: 40px;
}

.allServicesItemList {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 24px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.allServicesItemListItem {
  color: #1c1c1c;
  text-align: center;
  font-family: 'sofiapro';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  margin-bottom: 0;
}
