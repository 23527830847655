@import '../../styles/marketplace.css';

.homeContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  @media (--viewportMedium) {
    padding: 40px 0px 80px 0px;
  }

  @media (--viewportLarge) {
    gap: 64px;
  }
}
