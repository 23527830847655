@import '../../../styles/marketplace.css';

.services {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1280px;

  @media (--viewportLarge) {
    padding: 0px 24px;
  }
}

.servicesTitle {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 32 Heading */
  font-family: Bricolage Grotesque;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.64px;
  margin: 0;

  @media (--viewportLarge) {
    text-align: left;
    width: 100%;
  }
}

.servicesList {
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  gap: 48px 48px;
  align-self: stretch;
  flex-wrap: wrap;

  @media (--viewportLarge) {
    justify-content: space-between;
    gap: 48px 0px;
  }
}

.serviceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 140px;

  @media (--viewportLarge) {
    width: auto;
    flex-direction: row;
  }
}

.iconContainer {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #dfedf7;
}

.serviceItemTitle {
  color: #1c1c1c;
  text-align: center;
  font-family: 'sofiapro';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  margin: 0;

  @media (--viewportLarge) {
    text-align: left;
  }
}

.serviceItemDesc {
  color: #959595;
  text-align: center;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0;

  @media (--viewportLarge) {
    text-align: left;
  }
}

.hideOnLarge {
  display: flex;

  @media (--viewportLarge) {
    display: none;
  }
}
